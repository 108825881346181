export enum ButtonStyle {
    PRIMARY = 'PRIMARY',
    SECONDARY = 'SECONDARY',
    SECONDARY_OUTLINED = 'SECONDARY_OUTLINED',
    SECONDARY_DARK = 'SECONDARY_DARK',
    WARN = 'WARN',
    ACTION = 'ACTION',
    ACTION2 = 'ACTION2'
}

export enum ButtonSize {
    LARGE = 'LARGE',
    MEDIUM = 'MEDIUM',
    SMALL = 'SMALL'
}
