import { Component, EventEmitter, input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonSize, ButtonStyle } from '../buttons.model';
import { Params, RouterLink } from '@angular/router';

@Component({
    selector: 'app-custom-button',
    standalone: true,
    imports: [CommonModule, RouterLink],
    templateUrl: './custom-button-component.component.html',
    styleUrls: ['./custom-button-component.component.scss']
})
export class CustomButtonComponent {
    buttonStyle = input<keyof typeof ButtonStyle>(ButtonStyle.PRIMARY);
    buttonSize = input<keyof typeof ButtonSize>(ButtonSize.LARGE);
    isDisabled = input<boolean | undefined>(false);
    isContainsIcon = input(false);
    hrefLink = input<string>();
    link = input<any[] | string>();
    fragment = input('');
    buttonType = input<'submit' | 'reset' | 'button'>();
    target = input<'_self' | '_blank'>('_self');
    routerQueryParams = input<Params | null>(null);
    routerLinkState = input<
        | {
              [k: string]: any;
          }
        | undefined
    >();
    @Output() clicked = new EventEmitter();
}
