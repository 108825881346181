@if (!hrefLink() && !link()) {
    <button
        [disabled]="isDisabled()"
        [ngClass]="[
            buttonSize() | lowercase,
            buttonStyle() | lowercase,
            isContainsIcon() ? 'is-contains-icon' : ''
        ]"
        [type]="buttonType()"
        (click)="clicked.emit()">
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </button>
} @else if (hrefLink()) {
    <a
        [href]="hrefLink()"
        [target]="target()"
        [ngClass]="[
            buttonSize() | lowercase,
            buttonStyle() | lowercase,
            isContainsIcon() ? 'is-contains-icon' : ''
        ]"
        (click)="clicked.emit()">
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </a>
} @else {
    <a
        [routerLink]="link()"
        [queryParams]="routerQueryParams()"
        [fragment]="fragment() || undefined"
        [state]="routerLinkState()"
        [target]="target()"
        [ngClass]="[
            buttonSize() | lowercase,
            buttonStyle() | lowercase,
            isContainsIcon() ? 'is-contains-icon' : ''
        ]"
        (click)="clicked.emit()">
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </a>
}
<ng-template #content>
    <ng-content></ng-content>
</ng-template>
